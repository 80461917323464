import React, {useState} from 'react';
import classes from './Header.module.css';
import '../../index.css';
import Logo from '../Logo/Logo';
import { hamIcon } from '../../shared/fontAwesomeIcons/fontAwesomeIcons';
import { textConstants } from '../../constants/TextConstants';
import SchedulePickupPage from '../../containers/SchedulePickupPage/SchedulePickupPage';
import SideNavigation from '../SideNav/SideNavigation';

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={classes.header}>
            <div className={classes.logo_menu_container}>
                <div>
                    <Logo className={classes.logo} />
                </div>  
                <div className={classes.hamburger}>
                    <button onClick={toggleMenu}>
                        {hamIcon}
                    </button>
                </div>          
            </div>
            
            <div className={classes.header_container}>
                {/* <div className={[classes.header_content, "font_sm"].join(' ')}>
                    <a href={urlConstants.PHONE_NUMBER} title={urlConstants.PHONE_TEXT}>
                        {phoneIcon}&nbsp;&nbsp; {textConstants.CONTACT_DETAILS.PHONE} <br />
                    </a>
                    <p>
                        {clockIcon}&nbsp;&nbsp; {textConstants.CONTACT_DETAILS.TIMING}<br />
                    </p>  
                </div> */}
                {/* <div className={[classes.header_content, "font_sm"].join(' ')}>
                    <a href={urlConstants.GMAP_LOCATION_URL} target="_blank" rel="noreferrer nofollow">
                        {locationIcon}&nbsp;&nbsp; {textConstants.LOCATION_DETAILS.ADDRESSLINE1}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {textConstants.LOCATION_DETAILS.ADDRESSLINE2}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {textConstants.LOCATION_DETAILS.ADDRESSLINE3}<br />
                    </a>
                </div> */}
                <div className={[classes.header_content, "font_sm"].join(' ')}>
                    <SchedulePickupPage text={textConstants.BUTTON_TEXT.SCHEDULE_PICKUP} variant="button_variant_1"/>
                </div>
            </div>
            {menuOpen && <SideNavigation toggleMenu={toggleMenu} />}
        </div>
    )
}

export default Header;