import React from 'react';
import classes from './Form.module.css';
import { phoneIcon, clockIcon, locationIcon } from '../../../shared/fontAwesomeIcons/fontAwesomeIcons';
import { FormConstants } from '../../../constants/FormConstants';
import { urlConstants } from '../../../constants/UrlConstants';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import '../../../index.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CONTACT_ME_SHEET_ID, CLIENT_EMAIL, PRIVATE_KEY, doc, getCurrentDate, getCurrentDay } from '../../../shared/gsheetConstants';
import $ from 'jquery';

const formInformation = FormConstants.FORM_INFORMATION;
const contactDetails = FormConstants.FORM_INFORMATION.DETAILS.values;
const formFields = FormConstants.FORM_INFORMATION.FORM_INPUT.FORM_INPUT_FIELDS;

function getIcon (value) {
    switch(value) {
        case 'phoneIcon':
            return phoneIcon;
        case 'clockIcon':
            return clockIcon;
        case 'locationIcon':
            return locationIcon;
        default:
            return phoneIcon;
    }
}

function getLink (value) {
    switch(value) {
        case 'phoneIcon':
            return urlConstants.PHONE_NUMBER;
        case 'locationIcon':
            return urlConstants.GMAP_LOCATION_URL;
        default:
            return '/';
    }
}

// function checkIfFieldIsMessage (field) {
//     if (field === 'Message') {
//         return true;
//     }
//     return false;
// }

const Form = (props) => {

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const Alert = React.forwardRef((props, ref) => {
        return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
    });

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRequested, setIsRequested] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmissionDisabled, setDisable] = useState(true);
    
    function getMethod (e, field) {

        if (firstName !== null && lastName !== null && email !== null 
            && firstName !== '' && lastName !== '' && email !== '') {
            setDisable(false);
        } else {
            setDisable(true);
        }

        switch(field) {
            case formFields[0]: 
                return setFirstName(e.target.value);
            case formFields[1]: 
                return setLastName(e.target.value);
            case formFields[3]: 
                return setEmail(e.target.value);
            case formFields[4]: 
                return setMessage(e.target.value);
            case formFields[2]:
                return setPhone(e.target.value);
            default:
                return;
        }

       
    }

    async function handleSubmit(event) {
        setIsFormSubmitted(false);
        setIsRequested(true);
        handleClick();
        event.preventDefault();
        const newEntry = { 
            Date: getCurrentDate(),
            Day: getCurrentDay(),
            FirstName: firstName, 
            LastName: lastName , 
            Email: email, 
            Message: message, 
            ServiceInterested: props.service,
            PhoneNumber: phone
        };
        await submitGetInTouchForm(newEntry);
    }

    async function submitGetInTouchForm (row) {
        try {
            console.log(row);
            await doc.useServiceAccountAuth({
              client_email: CLIENT_EMAIL,
              private_key: PRIVATE_KEY.replace(/\\n/g, '\n')
            });
            // loads document properties and worksheets
            await doc.loadInfo();
            const sheet = doc.sheetsById[CONTACT_ME_SHEET_ID];
            await sheet.addRow(row);
            setIsFormSubmitted(true);
            setIsRequested(false);
            $("#contactMeForm")[0].reset();
          } catch (e) {
             // TODO: Check what needs to be done in case of failure
            setIsRequested(false);
            setIsFormSubmitted(false);
            setIsError(true);
          }
    }

    return(
        <div className={classes.form_container}>
            <div className={classes.information_container}>
                <div className={classes.information_container__title}>
                    {formInformation.TITLE}
                </div>
                <div className={classes.information_container__location}>
                    {formInformation.LOCATION.PLACE}<br/>
                    {formInformation.LOCATION.ADDRESSLINE_1}<br/>
                    {formInformation.LOCATION.ADDRESSLINE_2}<br />
                </div>
                <div className={classes.information_container__contact}>
                    <div className={classes.information_container__contact_detail}>
                        {contactDetails.map((detail, i) => {
                            return(
                                <div key={i}>
                                   <a href={getLink(detail.icon)} target="_blank" rel="noreferrer nofollow">
                                        <span>{getIcon(detail.icon)}</span>
                                        <span>{detail.text}</span>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={classes.form_input_container}>
                <div>
                    {formInformation.FORM_INPUT.FORM_TITLE}
                </div>
                <div>    
                    <form id="contactMeForm" onSubmit={handleSubmit}>
                    {
                        formFields.map((field, index) => {
                            return (
                                    <TextField key={index}
                                    className={classes.form_input}
                                        id={field} 
                                        label={field}
                                        onInput={ e=>getMethod(e, field)}
                                        variant="outlined" required/>
                            );
                        })
                    }
                    <div className={classes.button_container}>
                        {
                            <button className="button_variant_3" type="submit" disabled={isSubmissionDisabled}>GET IN TOUCH</button>
                        }
                        
                    </div>
                    </form>
                    
                </div>
            </div>
                {
                    isRequested ? <div className="spinner"> <CircularProgress /> </div>  
                    : isFormSubmitted ?
                    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Your request has been submitted
                    </Alert>
                    </Snackbar> : isError ? 
                    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                       Oops! Something went wrong. Please try again
                    </Alert>
                    </Snackbar> : 
                    <div></div>
                }
        </div>
    )
}

export default Form;