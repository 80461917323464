import React from 'react';
import classes from './PricingDetail.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { pricingValues } from '../../constants/Pricing';
import '../../index.css';
import {StyledTableCell, StyledTableRow} from '../../shared/table/table';

const servicesOffered = pricingValues.PRICING_DETAIL.SERVICES_OFFERED;

const PricingDetail = () => {
    return(
        <div>
            {/* <div className={[classes.pageTitle, "font_lg"].join(' ')}>
                {pricingValues.PRICING_DETAIL.TITLE}
            </div> */}
            <div className={classes.table_container}>
                    <TableContainer className={classes.table} component={Paper}>
                        {
                            servicesOffered.map((service, i) => {
                               return (
                                    <Table className={classes.tableStyle} aria-label="customized table" key={i}>
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell className={classes.serviceTitle}>
                                                <div className="font_md">
                                                    {service.serviceName}
                                                </div>
                                                <div className="font_md">
                                                    {service.price} ({service.price_unit})
                                                </div>
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell className={classes.serviceDescription}>
                                                <img className={classes.serviceImage} src={service.imageUrl} alt={service.serviceName} />
                                            </StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {service.details.map((row, index) => (
                                            <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {row}
                                            </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                               )
                            })
                        }
                    </TableContainer> 
            </div>
            <div className={[classes.termsAndCondition, "font_xs"].join(' ')}>
                                    {pricingValues.PRICING_DETAIL.GENERAL_TC}
            </div>
        </div>
    )
}

export default PricingDetail;