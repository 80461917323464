import React from 'react';
import {NavLink} from 'react-router-dom';
import classes from './SideNavigation.module.css';
import { textConstants } from '../../constants/TextConstants';

const navigationOptions = textConstants.NAVIGATION_OPTIONS_WITH_LINK.options;

const SideNavigation = ({toggleMenu}) => {

    const navigate = () => {
        toggleMenu();
    };


    return(
        <div className={classes.navigation_container}>
                <ul className={classes.navigation_container__list}>
                { navigationOptions.map((option, i) => {
                        return (<li className={classes.navigation_container__item} key={i}>
                            <NavLink onClick={navigate} activeClassName={classes.active} to={option.link}> {option.text} </NavLink>
                        </li>);
                    })
                }
                </ul>
        </div>
    )
}

export default SideNavigation;