import React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { pageContentConstants } from '../../constants/PageContentConstants';
import classes from './FaqPage.module.css';
import { Accordion,AccordionSummary,AccordionDetails } from '../../shared/accordion/accordion';


const QandA = pageContentConstants.FAQ_PAGE_CONTENT.QA;

const FaqPage = () => {
    const [expanded, setExpanded] = useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    return (
      <div className={classes.container}>
          <div className={classes.page_title}>Frequently asked Questions</div>
        {
            QandA.map((value, i) => {
               return(
                <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)} key={i}>
                <AccordionSummary aria-controls="panel-content" id={i}>
                    <Typography>{value.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {value.answer.line1}
                    {value.answer.line2}
                    </Typography>
                </AccordionDetails>
                </Accordion>
               );
            })
        }
      </div>
      )
}

export default FaqPage;