import React from 'react';
import classes from './Layout.module.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import { Container, Button } from 'react-floating-action-button';
import { whatsAppIcon, callIcon } from '../../shared/fontAwesomeIcons/fontAwesomeIcons';
import { urlConstants } from '../../constants/UrlConstants';

const Layout = (props) => {

    // scroll to top.
    const rootElement = document.documentElement;
    function scrollToTop() {
        console.log("Scrolling to top");
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div className={classes.layoutContainer}>
            <Header className={classes.layoutContainer__header} />
            <Navigation className={classes.layoutContainer__navigation} />
            <main className={classes.layoutContainer__mainContent}>
                {props.children}
            </main>
            <Footer clickHandler={scrollToTop} />
            <Container>
                <Button className={classes.whatsappButton}> 
                    <a href={urlConstants.WHATSAPP_URL} target="_blank" rel="noreferrer nofollow">
                        <span>{whatsAppIcon}</span> 
                    </a>
                </Button>
            </Container>

            <Container>
                <Button className={classes.phoneButton} > 
                    <a href={urlConstants.PHONE_NUMBER}>
                        <span>{callIcon}</span> 
                    </a>
                </Button>
            </Container>
            
        </div>
    );
};

export default Layout;