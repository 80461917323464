import React, { useState } from 'react';
import classes from './CommercialLaundry.module.css';
import '../../index.css';
import commercialLaundryImage from '../../assets/images/commercial-laundry-image.webp';
import { pageContentConstants } from '../../constants/PageContentConstants';
import { doc, COMMERCIAL_LAUNDRY_SHEET_ID, CLIENT_EMAIL, PRIVATE_KEY, getCurrentDate, getCurrentDay } from '../../shared/gsheetConstants';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';

const leftContainerInformation = pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.INFO_CONTAINER.DETAILS;
const formFields = pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.FORM_CONTAINER.FORM_INPUTS;

const CommercialLaundryPage = () => {

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const Alert = React.forwardRef((props, ref) => {
        return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
    });

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isRequested, setIsRequested] = useState('');
    const [isError, setIsError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [yourName, setYourName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [needs, setNeeds] = useState('');

    function getMethod (e, field) {
        switch(field) {
            case formFields[0]: 
                return setCompanyName(e.target.value);
            case formFields[1]: 
                return setYourName(e.target.value);
            case formFields[2]: 
                return setEmail(e.target.value);
            case formFields[3]: 
                return setPhone(e.target.value);
            case formFields[4]: 
                return setAddress(e.target.value);
            case "Laundry Needs": 
                return setNeeds(e.target.value);
            default:
                return;
        }
    }

    async function handleSubmit(event) {
        setIsFormSubmitted(false);
        setIsRequested(true);
        handleClick();
        event.preventDefault();
        const newEntry = { 
            Date: getCurrentDate(),
            Day: getCurrentDay(),
            CompanyName: companyName, 
            CustomerName: yourName , 
            Phone: phone, 
            Email: email, 
            Address: address,
            Needs: needs
        };
        await submitCommercialLaundryForm(newEntry);
    }

    async function submitCommercialLaundryForm (row) {
        try {
            await doc.useServiceAccountAuth({
              client_email: CLIENT_EMAIL,
              private_key: PRIVATE_KEY.replace(/\\n/g, '\n')
            });
            // loads document properties and worksheets
            await doc.loadInfo();
        
            const sheet = doc.sheetsById[COMMERCIAL_LAUNDRY_SHEET_ID];
            await sheet.addRow(row);
            setIsFormSubmitted(true);
            setIsRequested(false);
            $("#commercialForm")[0].reset();
          } catch (e) {
            // TODO: Check what needs to be done in case of failure
            setIsRequested(false);
            setIsFormSubmitted(false);
            setIsError(true);
          }
    }


    function checkIfEmail(field) {
        if (field === 'Email') {
            return true;
        }
        return false;
    }

    function checkIfPhone(field) {
        if (field === 'Phone Number') {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.container}>
            <div className={classes.full_width_container}>
                <div className={classes.container__image}>
                    <img src={commercialLaundryImage} alt="commercial-laundry"/>
                </div>
                <div className={classes.top_info_container}>
                    <div className={[classes.container__title, "font_lg"].join(' ')}>
                        {pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.TITLE}
                    </div>
                    <div className={[classes.container__description, "font_md"].join(' ')}>
                        {pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.DESCRIPTION}
                    </div>
                    {/* <div className={[classes.container__card, "font_llg"].join(' ')}>
                        {pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.CARD_CONTENT}
                    </div> */}
                </div>
            </div>

            <div className={classes.full_width_container}>
                <div className={classes.bottom_info_container}>
                    <div className={classes.left_container}>

                        {
                            leftContainerInformation.map((info, i) => {
                                return(
                                    <div key={i}>
                                        <div className={[classes.left_container__title, "font_lg"].join(' ')}>
                                            {info.title}
                                        </div>
                                        <div className={[classes.left_container__description, "font_md"].join(' ')}>
                                            {info.description}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                    <div className={classes.right_container}>
                       <div className={[classes.right_container__title, "font_lg"].join(' ')}>
                            {pageContentConstants.COMMERCIAL_LAUNDRY_PAGE_CONTENT.TITLE}
                       </div>
                       <div className={classes.right_container__form}>
                            <form id="commercialForm" onSubmit={handleSubmit}>
                                {
                                    formFields.map((field, i) => {
                                        return (

                                            <div className={classes.form_input} key={i}>
                                                <label>{field}</label>
                                                {
                                                    checkIfEmail(field) ? 
                                                    <input type="email" id={field} name={field} onInput={ e=>getMethod(e, field)} required/> :
                                                    checkIfPhone(field) ?
                                                        <input type="text" 
                                                        maxLength="10"
                                                        pattern="\d*"
                                                        id={field} name={field} onInput={ e=>getMethod(e, field)} required/>
                                                        : <input type="text" id={field} name={field} onInput={ e=>getMethod(e, field)} required/>
                                                }
                                            </div>

                                        );
                                    })
                                }
                                <div className={classes.right_container__form__textArea}>
                                    <label>Tell us about your laundry needs</label>
                                    <textarea rows="4"
                                    onInput={ e=>getMethod(e, "Laundry Needs")} required>
                                    </textarea>
                                </div>
                                <div className={classes.button_container}>
                                    <button className="button_variant_3" type="submit">SUBMIT</button>
                                </div>
                            </form>
                       </div>
                       {
                            isRequested ? <div className="spinner"> <CircularProgress /> </div>  
                            : isFormSubmitted ?
                            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                    Your request has been submitted
                                </Alert>
                            </Snackbar> : isError ? 
                            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                    Oops! Something went wrong. Please try again
                                </Alert>
                            </Snackbar> : 
                            <div></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommercialLaundryPage;