export const FormConstants = {
    FORM_INFORMATION: {
        TITLE: 'GENERAL INFORMATION',
        LOCATION: {
            PLACE: 'Fresh Linen',
            ADDRESSLINE_1: 'Old No. 52D, New No. 157/2, Velachery Main Road',
            ADDRESSLINE_2: 'Guru Nanak Road, Velachery, Chennai-600042'
        },
        DETAILS: {
            values: [
                {
                    icon: 'phoneIcon',
                    text: '+(91) 917 640 2233',
                },
                {
                    icon: 'locationIcon',
                    text: 'Get Directions',
                },
                {
                    icon: 'clockIcon',
                    text: '10AM - 7PM'
                }
            ]
        },
        FORM_INPUT: {
            FORM_TITLE: 'HAVE QUESTIONS? GET IN TOUCH!',
            FORM_INPUT_FIELDS: [
                'First Name',
                'Last Name',
                'Phone',
                'Email',
                'Message'
            ]
        }
    }
}