import React from 'react';
import classes  from './ContactUsPage.module.css';
import { pageContentConstants } from '../../constants/PageContentConstants';
import '../../index.css';
import Form from '../../components/Form/GetInTouch/Form';
import contactUsImage from '../../assets/images/contact-us-latest.png';

const ContactUsPage = () => {
    return (
        <div className={classes.container}>
            <div className={classes.info_container}>
                <div className={[classes.info_container__title, "font_lg"].join(' ')}>
                    {pageContentConstants.CONTACT_US_PAGE_CONTENT.TITLE}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {pageContentConstants.CONTACT_US_PAGE_CONTENT.DESCRIPTION.LINE1}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {pageContentConstants.CONTACT_US_PAGE_CONTENT.DESCRIPTION.LINE2}
                </div>
            </div>
            <div className={classes.contact_container}>

                <div className={classes.contact_image_container}> 
                    <img src={contactUsImage} alt="contact-us"></img>
                </div>

                <div className={classes.contact_form_container}>
                    <Form service="General query from contact us page"/>
                </div>

                
            </div>
            
        </div>
    )
}

export default ContactUsPage;