import React from 'react';
import classes from './PickupAndDelivery.module.css';
import '../../index.css';
import { pageContentConstants } from '../../constants/PageContentConstants';
import PickupAndDeliveryImg from '../../assets/images/pickup-and-delivery.jpg';
import Form from '../../components/Form/GetInTouch/Form';
import Circle from '../../components/Circle/Circle';
import SchedulePickupPage from '../SchedulePickupPage/SchedulePickupPage';


const pricing = pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.PRICING_DETAIL;
const description = pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.DETAILED_DESCRIPTION;

const PickupAndDelivery = () => {

    return(
        <div className={classes.container}>
            <div className={classes.info_container}>
            <div className={classes.info_container__image}>
                    <img src={PickupAndDeliveryImg} alt="pickup-and-delivery" />
                </div>
                <div className={[classes.info_container__title, "font_lg"].join(' ')}>
                    {pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.TITLE}
                </div>
                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.CARD_CONTENT_1}
                </div>

                <div className={classes.pricing_container}>
                   {pricing.map((priceDetail, i) => {
                        return (
                            <Circle key={i} content={priceDetail}></Circle>
                        );
                    })}
               </div>

                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {description.DESC_LINE1}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {description.DESC_LINE2.TEXT}
                    <ol>
                        <li>
                            {description.DESC_LINE2.DESC_SUBLINE1}
                        </li>
                        <li>
                            {description.DESC_LINE2.DESC_SUBLINE2}
                        </li>
                    </ol>
                </div>
                {/* <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {description.DESC_LINE3}
                </div> */}

                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.CARD_CONTENT_2}
                </div>

                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {description.DESC_LINE4}
                </div>
                
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {description.DESC_LINE5}
                </div>
                <div className={classes.info_container__action}>
                <SchedulePickupPage 
                    text={pageContentConstants.PICKUP_AND_DELIVERY_PAGE_CONTENT.BUTTON_TEXT} 
                    variant="button_variant_2"></SchedulePickupPage>
                </div>

            </div>
            <div className={classes.form_container}>
                <Form />
            </div>
        </div>
    )
}

export default PickupAndDelivery;

