
import React from 'react';
import { Carousel } from 'primereact/carousel';
import { textConstants } from '../../constants/TextConstants';
import { Link } from 'react-router-dom';
import styles from './ServiceCarousel.module.css';
import { pageContentConstants } from '../../constants/PageContentConstants'; 

function ServiceCarousel() {

    const serviceTemplate = (content) => {
        return (
            <div className={styles.container}>
                <div
                    className={styles.backgroundImage}
                    style={{
                        backgroundImage: `url(${content.image})`,
                    }}
                ></div>

                <div className={styles.content}>
                    <h1 className={`${styles.title} font_xlg`}>
                        {content.name}
                    </h1>
                    <p className={`${styles.description}`}>
                        {content.description}
                    </p>
                    <Link
                        to={content.navLink}
                        className={`${styles.link} font_md`}
                    >
                        Discover More
                        <span className={styles.arrow}>→</span>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className={[styles.services__title, 'font_lg'].join(' ')}>
                {pageContentConstants.HOME_PAGE_CONTENTS.SERVICES_SECTION_TITLE}
            </div>
            <Carousel autoplayInterval={3000} value={textConstants.OUR_SERVICES.services.options} numVisible={1} numScroll={1} itemTemplate={serviceTemplate} />
        </div>
    )
}

export default ServiceCarousel;