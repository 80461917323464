import React, { useEffect, useState, useRef } from 'react';
import classes from '../../components/Stats/Stats.module.css';

const Counter = ({ endValue }) => {
    const [count, setCount] = useState(0);
    const [hasStarted, setHasStarted] = useState(false);
    const counterRef = useRef(null);
    useEffect(() => {
        let startValue = 0;
        const duration = 2000; // The duration of the animation in ms (2 seconds)
        const increment = endValue / (duration / 10);

        if (hasStarted) {
            const counter = setInterval(() => {
                startValue += increment;
                if (startValue >= endValue) {
                    clearInterval(counter);
                    setCount(endValue); // Set the final value once counting is complete
                } else {
                    setCount(Math.ceil(startValue)); // Update the counter state
                }
            }, 10); // Update every 10ms

            return () => clearInterval(counter); // Cleanup on unmount
        }
    }, [endValue, hasStarted]);

    useEffect(() => {
        const observerCallback = ([entry]) => {
            if (entry.isIntersecting) {
                setHasStarted(true);
                observer.disconnect(); // Stop observing once the counter starts
            }
        };
        const observer = new IntersectionObserver(observerCallback, { threshold: 0.1 });
        const currentRef = counterRef.current; // Create a local variable for cleanup
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };

    }, []);

    return (
        <span ref={counterRef} className={classes.stats_item_counter}>
            {count.toLocaleString()} +
        </span>
    );
};

export default Counter;