import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Logo.module.css';
import appLogo from '../../assets/images/logo.jpg';

const Logo = () => {
    return(
        <div className={classes.Logo}>
            <NavLink to="/home">
                <img src={appLogo} alt="elite-laundry-logo"/>
            </NavLink>
        </div>
    );
}

export default Logo;