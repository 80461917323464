import React from 'react';
import classes from './WashAndFold.module.css';
import '../../index.css';
import { pageContentConstants } from '../../constants/PageContentConstants';
import Circle from '../../components/Circle/Circle';
import Form from '../../components/Form/GetInTouch/Form';
import WashAndFoldImage from '../../assets/images/wash-and-fold.jpg';
import SchedulePickupPage from '../SchedulePickupPage/SchedulePickupPage';

const WashAndFold  = () => {

    return(
        <div className={classes.container}>
            <div className={classes.info_container}>
                <div className={classes.info_container__image}>
                    <img src={WashAndFoldImage} alt="wash-and-fold" />
                </div>
                <div className={[classes.info_container__title, "font_lg"].join(' ')}>
                    {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.TITLE}
                </div>
                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.CARD_CONTENT_1}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.DESCRIPTION_1}
                </div>
                <div className={[classes.info_container__details, "font_md"].join(' ')}>
                    <div className={classes.info_container__details__content}>
                        {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.DESCRIPTION_2}
                        <div className={classes.info_container__details__action}>
                            <div className={[classes.info_container__details__action_description, "font_lg"].join(' ')}>
                                {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.ACTION.description}
                            </div>
                            
                            <SchedulePickupPage 
                            text={pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.ACTION.text} 
                            variant="button_variant_2"></SchedulePickupPage>
                        </div>
                    </div>
                    <div className={classes.info_container__details__price}>
                        <Circle content={pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.PRICING_DETAIL}></Circle>
                    </div>
                </div>
                <div className={classes.info_container__description}>
                    <div>
                        {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.DESCRIPTION_3.PART_1}
                    </div>
                    <div>
                        {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.DESCRIPTION_3.PART_2}
                    </div>
                    <div>
                        {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.DESCRIPTION_3.PART_3}
                    </div>
                </div>
                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.WASH_AND_FOLD_PAGE_CONTENT.CARD_CONTENT_2}
                </div>
            </div>

            <div className={classes.form_container}>
                <Form service="Wash &amp; Fold" />
            </div>
        </div>
    )
}

export default WashAndFold;