import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1A2357',
      color: '#fff'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      height: '70px !important'
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#e8e9ee',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#babdcc',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

export {StyledTableCell, StyledTableRow};