import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faClock, faMapMarkerAlt, faTimesCircle, faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../../index.css';

const fbIcon = <FontAwesomeIcon className="footer_icons font_lg" icon={faFacebook} />
const igIcon = <FontAwesomeIcon className="footer_icons font_lg" icon={faInstagram} />
const twitterIcon = <FontAwesomeIcon className="footer_icons font_lg" icon={faTwitter} />
const phoneIcon = <FontAwesomeIcon className="header_icons font_md" icon={faPhoneAlt} />;
const clockIcon = <FontAwesomeIcon className="header_icons font_md" icon={faClock} />;
const locationIcon = <FontAwesomeIcon className="header_icons font_md" icon={faMapMarkerAlt} />;
const modalCloseIcon = <FontAwesomeIcon className="close_icon font_lg" icon={faTimesCircle} />;
const whatsAppIcon = <FontAwesomeIcon className="footer_icons font_lg" icon={faWhatsapp} />;
const callIcon = <FontAwesomeIcon className="footer_icons font_lg" icon={faPhoneAlt} />;
const hamIcon = <FontAwesomeIcon className='header_icons font_lg' icon={faBars} />;
const faChevronRightIcon = <FontAwesomeIcon className='header_icons font_md' icon={faChevronRight} />;

export {phoneIcon, clockIcon, locationIcon, fbIcon, igIcon, twitterIcon, modalCloseIcon, whatsAppIcon, callIcon, hamIcon, faChevronRightIcon};