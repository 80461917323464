import girlLaundry from '../assets/images/girl-laundry.jpg';
import commercialLaundry from '../assets/images/commercial-laundry-image.webp';
import washAndFold from '../assets/images/wash-and-fold.jpg';
import pickUpAndDelivery from '../assets/images/pickup-delivery-image.jpg'

export const textConstants = {
    NAVIGATION_OPTIONS_WITH_LINK: {
        options: [
            {
                text: 'Home',
                link: '/home'
            },
            {
                text: 'Wash & Fold',
                link: '/wash-and-fold'
            },
            {
                text: 'Dry Cleaning',
                link: '/dry-cleaning'
            },
            {
                text: 'Commercial Laundry',
                link: '/commercial-laundry'
            },
            {
                text: 'Pickup & Delivery',
                link: '/pickup-and-delivery'
            },
            {
                text: 'Pricing',
                link: '/pricing'
            },
            {
                text: 'About Us',
                link: '/about-us'
            }
        ]
    },
    CONTACT_DETAILS: {
        PHONE: '+ (91) 917 640 2233',
        TIMING: '10AM - 7PM',
        OTHER_COMMENTS: 'Open 7 Days a Week!'
    },
    LOCATION_DETAILS: {
        ADDRESSLINE1: 'Old No. 52D, New No. 157/2',
        ADDRESSLINE2: 'Velachery Main Road, Guru Nanak Road',
        ADDRESSLINE3: 'Velachery, Chennai-600042'
    },
    FOOTER_OPTIONS_WITH_LINK: {
        options: [
            {
                text: 'About Us',
                link: '/about-us'
            },
            {
                text: 'FAQs',
                link: '/faq'
            },
            {
                text: 'Terms',
                link: '/terms'
            },
            {
                text: 'Contact Us',
                link: '/contact-us'
            }
        ]
    },
    BUTTON_TEXT: {
        SCHEDULE_PICKUP: 'SCHEDULE PICKUP',
        KNOW_MORE: 'Know more',
        VIEW_ALL: 'Learn more about pricing'
    },
    OUR_SERVICES: {
        services: {
            options: [
                {
                    name: 'Wash & Fold',
                    description: 'Laundry is our way of life. It does NOT have to be yours. Drop off your clothing. Fresh Linen offers full laundry services and will do it for you!',
                    image: washAndFold,
                    navLink: '/wash-and-fold'
                },
                {
                    name: 'Dry Cleaning',
                    description: 'Professional dry cleaning and shirts laundered and pressed. We are a one-stop laundry service store for all your laundry and dry cleaning needs.',
                    image: girlLaundry,
                    navLink: '/dry-cleaning'
                },
                {
                    name: 'Pickup & Delivery',
                    description: 'FREE pick up and delivery laundry service. We will wash and fold your clothes; both residential and commercial laundry.',
                    image: pickUpAndDelivery,
                    navLink: '/pickup-and-delivery'
                },
                {
                    name: 'Commercial Laundry',
                    description: 'Let us take care of all of your business laundry needs. We offer top quality commercial laundry services; we will pick up and deliver your towels, linens, and uniforms.',
                    image: commercialLaundry,
                    navLink: '/commercial-laundry'
                }
            ]
        }
    },
    LAUNDRY_PRICING: {
        pricing: {
            options: [
                {
                    service: 'Wash & Fold',
                    description: 'Drop Off Service',
                    price: '₹ 100',
                    price_unit: 'Per Kg*',
                    tc: "* - Minimum 4 kgs"
                },
                {
                    service: 'Weekly',
                    description: 'Pickup & Delivery',
                    price: '₹ 80',
                    price_unit: 'Per Kg*',
                    tc: "* - Minimum 4 kgs"
                },
                {
                    service: 'By Request',
                    description: 'Pickup & Delivery',
                    price: '₹ 85',
                    price_unit: 'Per Kg*',
                    tc: "* - Minimum 4 kgs"
                }
            ]
        }
    },
    CUSTOMER_TESTIMONIALS: {
        values: [
            {
                customerName: 'Studio11 KK Nagar',
                customerReview: 'The salon is in KK NAGAR but was referred by my friend and I must say it was good to follow the lead. They had picked up towels and delivered it on time. If this was the only service I would have said good but what made it exceptional was the stain from the Tan/keratin and other treatments were delivered stainless and the assortment of towels was the brownie point.'
            },
            {
                customerName: 'Sowmyan R',
                customerReview: 'Very courteous and professional. They understood the need unlike a lot of the other laundries. Was promptly delivered, well packed. Very happy with the process and outcome.'
            },
            {
                customerName: 'Mahalakshmi',
                customerReview: 'Reliable, value for money and the place is clean and hygienic. You can see the workplace functioning. Courteous Staff and administration.'
            },
            {
                customerName: 'Chitra Rao',
                customerReview: 'I was just waiting to have a professional laundry who could give excellent services at a reasonable price at my doorstep. I got all that in Fresh Linen Launders. My first experience was excellent. You can even walk in and see for yourself how your clothes are treated. No hard water or chemicals for your clothes. Well done. Keep it up'
            }
        ]
    },
    HOME_PAGE_CAROUSEL_CONTENT: {
        values: [
            {
                title: "FULL SERVICE WASH & FOLD LAUNDROMAT",
                subTitle: "Same day Wash & Fold Laundry Services available.",
                image: ""
            },
            {
                title: "COMMERCIAL LAUNDRY & DRY CLEANING SERVICES",
                subTitle: "Professional Laundry & Linen Services for businesses.",
                image: ""
            },
            {
                title: "FREE PICKUP AND DELIVERY SERVICES",
                subTitle: "Free pickup and delivery services available in and around Velachery.",
                image: ""
            }
        ]
    }
}