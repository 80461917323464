import React from 'react';
import classes from './Stats.module.css';
import Counter from '../../shared/counter/counter';

const Stats = () => {
  return (
    <div className={classes.stats_container}>
      <div className={classes.stats_item}>
        <div><Counter endValue={5000}/></div>
        <p>Customers</p>
      </div>
      <div className={classes.vertical_line}/>
      <div className={classes.stats_item}>
        <div><Counter endValue={400000}/></div>
        <p>Clothes Washed</p>
      </div>
      <div className={classes.vertical_line}/>
      <div className={classes.stats_item}>
        <div><Counter endValue={100000}/></div>
        <p>Dry Cleaned</p>
      </div>
      <div className={classes.vertical_line}/>
      <div className={classes.stats_item}>
        <div><Counter endValue={500000}/></div>
        <p>Steam & Ironed</p>
      </div>
    </div>
  );
};

export default Stats;
