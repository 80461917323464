import React, { useState, useRef, useEffect, useCallback } from 'react';
import classes from './SchedulePickupPage.module.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { pageContentConstants } from '../../constants/PageContentConstants';
import {
  SCHEDULE_PICKUP_SHEET_ID,
  CLIENT_EMAIL,
  PRIVATE_KEY,
  doc,
  getCurrentDate,
  getCurrentDay,
} from '../../shared/gsheetConstants';
import './SchedulePickupPage.module.css';

const SchedulePickupPage = (props) => {
  const formFields = pageContentConstants.SCHEDULE_PICKUP_FORM_CONTENT.FORM_INPUT_FIELDS;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [yourName, setYourName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 30);
  const [pickupDate, setPickupDate] = useState(currentDate);

  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
  const [deliveryDate, setDeliveryDate] = useState(twoDaysFromNow);

  const [address, setAddress] = useState('');

  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});

  const toast = useRef(null);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 2000 });
  };

  // Track which fields have been touched
  const handleTouch = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const validateFields = useCallback(() => {
    let errors = {};
    if (!yourName.trim()) errors.name = 'Please enter your name';
    if (!phone.trim()) errors.phone = 'Please enter your phone number';
    if (!email.trim()) errors.email = 'Please enter a valid email';
    if (!pickupDate) errors.pickupDate = 'Please select a pickup date';
    if (!address.trim()) errors.address = 'Please enter your address';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [address, yourName, email, pickupDate, phone]);


  useEffect(() => {
    setIsFormValid(validateFields()); // Set form validity whenever fields change
  }, [validateFields]);


  const getMethod = (e, field) => {
    switch (field) {
      case formFields[0]:
        return setYourName(e.target.value);
      case formFields[1]:
        return setPhone(e.target.value);
      case formFields[3]:
        return setEmail(e.target.value);
      case formFields[2]:
        return setAddress(e.target.value);
      default:
        return;
    }
  };

  const validateDeliveryDateTime = (value) => {
    const date = new Date(value);
    const deliveryDate = date.setDate(date.getDate() + 2);
    setDeliveryDate(new Date(deliveryDate));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validateFields()) return; // Custom validation
    setIsRequested(true);

    const newEntry = {
      Date: getCurrentDate(),
      Day: getCurrentDay(),
      Name: yourName,
      Address: address,
      Email: email,
      PhoneNumber: phone,
      PickupDateTime: toIST(pickupDate),
      DeliveryDateTime: toIST(deliveryDate),
    };
    await submitSchedulePickupForm(newEntry);
  }

  async function submitSchedulePickupForm(row) {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY.replace(/\\n/g, '\n'),
      });
      await doc.loadInfo();
      const sheet = doc.sheetsById[SCHEDULE_PICKUP_SHEET_ID];
      await sheet.addRow(row);
      setIsRequested(false);
      showToast('success', 'Success', 'Your pickup has been scheduled.');
      closeModal();
    } catch (e) {
      setIsRequested(false);
      showToast('error', 'Error', 'Oops! Something went wrong.');
    }
  }

  const toIST = (date) => {
    if (!date) return null;
    const utcOffset = 5.5 * 60; // IST is UTC+5:30
    return new Date(date.getTime() + utcOffset * 60 * 1000);
  };

  return (
    <div className={classes.modal_container}>
      <Toast ref={toast} position="top-right" />
      <Button label={props.text} onClick={openModal} />
      <Dialog header="Schedule Pickup" visible={modalIsOpen} onHide={closeModal} modal className="p-fluid">
        <form className={classes.modal_form} onSubmit={handleSubmit} id="schedulePickupForm">
          {formFields.map((field, i) => (
            <div className={classes.form_input} key={i}>
              <label>{field}</label>
              {field === 'Email' ? (
                <InputText
                  type="email"
                  id={field}
                  value={email}
                  onChange={(e) => {getMethod(e, field); handleTouch(field);}}
                  required
                />
              ) : field === 'Phone Number' ? (
                <InputText
                  type="number"
                  id={field}
                  value={phone}
                  onChange={(e) => {getMethod(e, field); handleTouch(field);}}
                  required
                />
              ) : (
                <InputText
                  type="text"
                  id={field}
                  value={field === 'Name' ? yourName : address}
                  onChange={(e) => {getMethod(e, field); handleTouch(field);}}
                  required
                />
              )}
              {touchedFields[field] && formErrors[field.toLowerCase()] && (
                <small className={classes.p_error}>{formErrors[field.toLowerCase()]}</small>
              )}
            </div>
          ))}

          <div className={classes.date_time_picker_container}>
            <div className={classes.date_time_picker}>
              <label>Pickup Date Time</label>
              <Calendar
                value={pickupDate}
                onChange={(e) => {
                  setPickupDate(e.value);
                  validateDeliveryDateTime(e.value);
                  handleTouch('pickupDate')
                }}
                showTime
                hourFormat="12"
                showIcon
                required
              />
             {touchedFields['pickupDate'] && formErrors.pickupDate && (
                <small className={classes.p_error}>{formErrors.pickupDate}</small>
              )}
            </div>

            <div className={classes.date_time_picker}>
              <label>Delivery Date Time</label>
              <Calendar
                value={deliveryDate}
                onChange={(e) => {setDeliveryDate(e.value); handleTouch('deliveryDate')}}
                showTime
                hourFormat="12"
                showIcon
                required
              />
            </div>
          </div>

          <div className={classes.form_button}>
            <Button label="PICKUP" type="submit" disabled={!isFormValid} />
          </div>
        </form>

        {isRequested && (
          <div className="spinner">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SchedulePickupPage;
