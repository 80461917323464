import React from 'react';
import classes from './Circle.module.css';
import '../../index.css';

const Circle = (props) => {
    
    return(
            <div className={classes.circle}>
                <div className={[classes.circle_head, "font_lg"].join(' ')}>
                    {props.content.service}
                </div>
                <div className={[classes.circle_head, "font_lg"].join(' ')}>
                    {props.content.description}
                </div>
                <div className={[classes.circle_price, "font_xlg"].join(' ')}>
                    {props.content.price}
                </div>
                <div className={[classes.circle_price_unit, "font_lg"].join(' ')}>
                    {props.content.price_unit}
                </div>
                <div className={[classes.circle_terms_condition, "font_sm"].join(' ')}>
                    {props.content.tc}
                </div>
            </div>
    )
}

export default Circle;