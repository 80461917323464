export const pageContentConstants = {
    HOME_PAGE_CONTENTS: {
        TITLE: '',
        DESCRIPTION: 'Fresh Linen Launders was built with an intent to revolutionize the unorganized laundry service into an organized professionally managed retail store and provide e-commerce service.  Our goal is to provide a warm welcoming laundry service with top of the line equipment and exceptional customer service. We are an expert at cleaning and handling clothes. We provide the best possible laundry service for all of our customers. We know doing laundry can be a tedious task, it’s our mission is to take care of your laundry needs effortless and enjoyable with our cutting edge technology and state of the art facility.',
        SERVICES_SECTION_TITLE: 'What We Offer',
        PRICING_SECTION_TITLE: 'Laundry Service Pricing',
        TESTIMONY_SECTION_TITLE: 'What Our Customers are saying',
        COPYRIGHT_TEXT: ' Fresh Linen. All rights reserved.'
    },
    WASH_AND_FOLD_PAGE_CONTENT: {
        TITLE: 'Wash & Fold Laundry Drop Off Service in Chennai',
        CARD_CONTENT_1: 'We offer drop-off and pick-up Wash & Fold laundry service in Chennai. Same day service is available for wash & fold, and special requests can be accommodated.',
        DESCRIPTION_1: 'We call our wash and fold service our four-minute laundry. It will take you two minutes to drop off laundry and two minutes to pick it up! We will wash, dry, and fold your clothes for you, so that you have more time for you and your family. If you are looking for wash and fold service in Chennai or a full-service laundromat, you have found the right place.',
        DESCRIPTION_2: 'Fresh Linen drop off laundry service is only Rs. 70/Kg with a 4 Kg as a minimum order. We are open from 10 AM to 8 PM for wash and fold laundry service. We promise next day service, but if we have the time and you are in a hurry, let us know- we will always do our best to have it ready for you on the same day.',
        PRICING_DETAIL: {
            service: 'Drop-Off',
            description: 'Laundry Service',
            price: '₹ 100',
            price_unit: 'Per Kg*',
            tc: "* - Minimum 4 kgs"
        },
        ACTION: {
            description: 'No Time To Drop Off?',
            text: 'SCHEDULE A PICKUP TODAY'
        },
        DESCRIPTION_3: {
            PART_1: 'We use premium organic and biodegradable detergents, bleaches, and softeners for your laundry. Your clothes will be separated by white, light-coloured, and dark-coloured loads. Your wash and fold service are handled separately from other customers – clothes are never mixed. We pack your clothes in bags and keep orders separated.',
            PART_2: 'Large capacity service is also available for wash & fold. There are special charges for large items such as comforters, mattress pads, pillows, and sleeping bags.',
            PART_3: 'We also offer Commercial accounts for local businesses in need of commercial laundry service.'
        },
        CARD_CONTENT_2: 'We take top notch care of your clothes with our wash & fold service. Laundry is our business!!!',
    },
    DRY_CLEANING_PAGE_CONTENT: {
        TITLE: 'Dry Cleaners with Pickup & Delivery Service in Chennai',
        CARD_CONTENT_1: 'If you are looking for an ecologically sound dry cleaner in Chennai, visit Fresh Linen. We welcome all sizes of orders from just a few items to large orders.',
        DESCRIPTION_1: 'Fresh Linen is a local organic dry cleaner in Velachery, Chennai. We offer drop off service and pickup and delivery service for your dry cleaning. If you are coming to Fresh Linen to do your laundry, why not bring your dress shirts, dresses, and suits for dry cleaning too? We will take care of all your dirty laundry needs at one place.',
        DESCRIPTION_2: 'Our dry-cleaning pickup and drop off service is available from 10 AM to 7 PM. We have a two-day turnaround time, and we will notify you by text message as soon as your garments are ready for pick up.',
        CARD_CONTENT_2: 'EXPRESS DRY CLEANING is also available at an additional cost. We offer you a 2 Hour Delivery Commitment',
        ACTION_TEXT: 'SCHEDULE A DRY CLEANING PICKUP'
    },
    PICKUP_AND_DELIVERY_PAGE_CONTENT: {
        TITLE: 'Laundry Pick Up & Delivery Services Available In Velachery And Surrounding localities',
        CARD_CONTENT_1: 'Fresh Linen offers top notch laundry pickup and delivery service in Chennai.',
        CARD_CONTENT_2: 'If you are looking for a laundry pick up service in Chennai, let Fresh Linen help you!',
        BUTTON_TEXT: 'SCHEDULE A PICKUP',
        PRICING_DETAIL:[ 
            {
                service: 'By Request',
                description: 'Pickup & Delivery',
                price: '₹ 85',
                price_unit: 'Per Kg*',
                tc: "* - Minimum 4 kgs"
            },
            {
                service: 'Weekly',
                description: 'Pickup & Delivery',
                price: '₹ 80',
                price_unit: 'Per Kg*',
                tc: "* - Minimum 4 kgs"
            }
    ],
    DETAILED_DESCRIPTION: {
        DESC_LINE1: 'Not only do we wash, dry, and fold your clothes, but we will pick up and deliver them to your doorstep as well!',
        DESC_LINE2: {
            TEXT: 'There are two options for laundry pick-up and delivery service.',
            DESC_SUBLINE1: 'You can schedule us for weekly pick up. By committing to a weekly schedule, we have a reduced rate of only Rs. 60 per Kg',
            DESC_SUBLINE2: 'For those who want a flexible pick up and delivery service for as needed, Fresh Linen charges Rs. 80 per Kg'
        },
        DESC_LINE3: '*Please note- our laundry delivery service has a Rs. 300 minimum charge.',
        DESC_LINE4: 'We are experts in laundry pick-up and delivery, have a dedicated driving crew, and offer easy online scheduling. We cover many areas in Chennai.',
        DESC_LINE5: 'Click on the "Schedule A Pickup" button and let’s get started!'
    }
    },
    COMMERCIAL_LAUNDRY_PAGE_CONTENT: {
        TITLE: 'Commercial Laundry & Linen Services with Pickup & Delivery',
        DESCRIPTION: 'Our professional laundry service will wash and fold clothes for businesses in and around Velachery, Chennai. Please contact Fresh Linen at support@freshlinen.in (or fill out the form below) or call our manager at +91 91764 02233 so we can get you started. If no special pricing is required, you can simply schedule a pickup online. Tell us how often you want your commercial laundry picked up and how often you want to be billed. We’ll work together to ensure that Fresh Linen commercial laundry department can assist you.',
        CARD_CONTENT: 'Fresh Linen specializes in commercial laundry.',
        INFO_CONTAINER: {
            DETAILS: [
                {
                    title: 'Commercial Laundry & Linen Service for Hotels & Paying Guests',
                    description: 'Fresh Linen does commercial linen service for many small hotels and Paying Guests. We specialize in hotels and Paying Guests with less than 25 beds. Because we can return linens within 24-hours, these hotels and PG’s are pleased with our service.'
                },
                {
                    title: 'Commercial Laundry & Linen Service for Spas and Gyms',
                    description: 'Spas and gyms have a significant number of towels and sheets. Fresh Linen specializes in commercial laundry service and gets the job done, timely and thoroughly, whether items are dropped off at our store, or a pickup service is arranged. We\'ve got you covered!'
                },
                {
                    title: 'Commercial Laundry & Linen Service for Uniforms',
                    description: 'Sports uniforms are another item that we specialize in. Fresh Linen launders lacrosse uniforms, football uniforms, basketball uniforms, and baseball uniforms for high schools and colleges. We are specialists in getting the tough stains out!'
                },
                {
                    title: 'Commercial Laundry & Linen Service for Pet Shops',
                    description: 'Pet grooming shops use plenty of towels! Don’t worry-we will wash, dry, and fold them before we weigh them. You don’t want to pay for wet towels!'
                }
            ]
        },
        FORM_CONTAINER: {
            TITLE: 'Get a Custom Quote For Your Business\'s Laundry Needs!',
            FORM_INPUTS: [
                "Company Name",
                "Your Name",
                "Email",
                "Phone Number",
                "Business Address (city, state, zip)"
            ] 
        }
    },
    ABOUT_US_PAGE_CONTENT: {
        TITLE: 'About Fresh Linen​ Launders & Dry Cleaners',
        DESCRIPTION_1: 'We are a Well-Organised and Vibrant team, who stepped into deliver Quality and Cost Benefiting laundry solution that has been built to wipe away all your laundry woes. Fresh Linen is environmentally responsible using only organic and eco-friendly solvents and enzymes to clean your garments. At Fresh Linen, we respect your love and sentiments towards your garments and believe they deserve better laundry service with utmost care. In order to achieve it, we provide laundry service with state-of-the-art branded machineries, premium detergents powered by Industry professionals.',
        CARD_CONTENT: 'Fresh Linen​ is a full-service laundromat, Dry Cleaner, and Commercial laundry provider.',
        DESCRIPTION_2: 'Fresh Linen is the best online platform that provides an economical and eco – friendly and hygienic laundry & washing service in Chennai. Fresh Linen picks your laundry at your doorsteps based on your suitable pre-scheduled time, wash it according to the garments specification and optimum stain removing and cleaning. We commit to deliver back at your doorsteps within 48 hours. We use State of the Art Machineries imported from Germany which are gentle on your garments but tough on strains. We only use Enzyme based cleaning products that are dermatologically tested and is safer for your skin while being ecologically responsible. For this, we have partnered with Diversey, who have been in the industry for over 95 years for providing hygiene, infection free, and cleaning.  Fresh Linen is the best residential laundry & Steam Ironing service in Chennai to take away all your laundry worries.',
    },
    CONTACT_US_PAGE_CONTENT: {
        TITLE: 'Contact Us Today!',
        DESCRIPTION: {
            LINE1: 'Thank you for visiting our website.',
            LINE2: 'Please give us a call or If you’d like to leave us a message about our services or have further questions, please do so via the contact form below.'
        }
    },
    SCHEDULE_PICKUP_FORM_CONTENT : {
        FORM_TITLE: '',
        FORM_INPUT_FIELDS: [
            'Name',
            'Phone Number',
            'Address',
            'Email',
        ]
    },
    FAQ_PAGE_CONTENT : {
        QA: [
            {
                question: 'Why do I need Fresh Linen services?',
                answer: {
                    line1: 'Fresh Linen makes laundry easy for you. Schedule a pick up date and time, we will be at your doorstep in a wink.',
                    line2: 'Secondly you don\'t even have to prepare your laundry, our laundry pick up boy knocks your door and packs the dirty laundry in Fresh Linen bags. He tags your laundry as every garment has its own washing instructions. In no time, enjoy washed, ironed laundry back home. Fresh Linen is an affordable and premier laundry destination.'
                }
            },
            {
                question: 'By when will I get my laundry back?',
                answer: {
                    line1: 'If you have selected Express Delivery, you will get the laundry in 2-4 hours.',
                    line2: 'If you have selected Normal Delivery, your laundry will be delivered in 2-3 days.'
                }
            },
            {
                question: 'What is the process to schedule a pick up?',
                answer: {
                    line1: 'Visit our website and schedule a pick up. You can choose your laundry pick up time during scheduling the laundry. ',
                    line2: 'You can select the date and time as per your convenience.'
                }
            },
            {
                question: 'What are the timings of FreshLinen?',
                answer: {
                    line1: 'We are open from 10:00 AM to 07:00 PM. Call us, mail us or ping us to get in touch.',
                    line2: 'We will be right back at your service.'
                }
            },
            {
                question: 'Which regions you offer your services like Pick-Up and Drop?',
                answer: {
                    line1: 'We serve all across South Chennai.',
                    line2: 'You can choose Express delivery where the delivery will be done in 2-4 hours or you can choose Normal delivery where your laundry will be delivered in 2-3 days.'
                }
            },
            {
                question: 'How clean is your store?',
                answer: {
                    line1: 'The cleanliness of our store is our pride. In fact we have special team to handle the cleanliness of the store.',
                    line2: 'You will never find a better laundry destination than Fresh Linen in Chennai.'
                }
            },
            {
                question: 'Do you wash my clothes with other people clothes?',
                answer: {
                    line1: 'We understand what hygiene means to you. Yes though we wash your clothes with other people clothes, hygiene is highly maintained by our management team in terms of machinery and environment.',
                    line2: ''
                }
            },
            {
                question: 'How will I pay to Fresh Linen?',
                answer: {
                    line1: 'Fresh Linen offers online payment or Cash on Delivery.',
                    line2: ''
                }
            },
            {
                question: 'Do you use any fabric softeners?',
                answer: {
                    line1: 'With quality and customer satisfaction being the prime ambitions of Fresh Linen, we use the best resources available in the industry.',
                    line2: 'We use international brands like Diversey, Christeyns for washing your garments.'
                }
            },
            {
                question: 'Do you do Dry Cleaning?',
                answer: {
                    line1: 'Fresh Linen is a premier one stop laundry solution.',
                    line2: 'We do dry cleaning too. From hanky to blankets we serve all your home dry cleaning needs.'
                }
            },
            {
                question: 'Do you wash quilts, blankets and comforters?',
                answer: {
                    line1: 'Yes from small hanky to huge quilts, blankets and comforters, we offer laundry. We own high end equipments which are efficient.',
                    line2: ''
                }
            },
            {
                question: 'What will you do if my clothes are damaged when I receive them?',
                answer: {
                    line1: 'Firstly our expert laundry care team will handle your garments with care. They own specialized experience in garment laundry service.',
                    line2: 'But unfortunately if you find any garment damaged, we will compensate to your loss to a maximum of 3 times your bill value. Fresh Linen will do its best to sort out the issue effectively.'
                }
            },
            {
                question: 'What if I leave any personnel items in my pockets by mistake?',
                answer: {
                    line1: 'We shall check the laundry while pick up and return back if we find any personnel belongings. In case we find any at our store during the laundry preparing or cleaning process, we will take care of them and shall call you to let you know about the same.',
                    line2: ''
                }
            },
            {
                question: 'I have a delicate garment that needs special attention. What should I do?',
                answer: {
                    line1: 'While you schedule a pick up on the website, please mention in the remarks section what garment needs special attention.',
                    line2: 'You can also give a single remark if the entire garments require special attention.'
                }
            },
            {
                question: 'What should I do if I miss clothing from the returned laundry bag?',
                answer: {
                    line1: 'We care your laundry very well and make sure we return only clean laundry and nothing else. Our professional management team handles the to and fro of garments with flawless expertise.',
                    line2: 'But in case you didn\'t find any of your garment in the return bag from Fresh Linen please call our customer care and keep the order id handy.'
                }
            },
            {
                question: 'Can I get the garments ironed after wash?',
                answer: {
                    line1: 'Your garments are washed with our premium detergents and ironed. Ironing is included.',
                    line2: ''
                }
            },
            {
                question: 'What if I am not at home when you drop the laundry?',
                answer: {
                    line1: 'No worries. We will contact you before we start to make sure you are at home. Only then we start and drop your laundry.',
                    line2: ''
                }
            },
            {
                question: 'Can I have a fast laundry delivery service?',
                answer: {
                    line1: 'Yes we have Express laundry delivery service.',
                    line2: 'This premium delivery services brings back your laundry within 2-4 hours from the time of pick up. The price is double.'
                }
            },
            {
                question: 'Do I have to prepare the laundry before giving it to you?',
                answer: {
                    line1: 'Of course No. You don\'t have to.',
                    line2: 'Leave your laundry worries with us. We will pack them and take it. In no time, we will come back with your clean laundry.'
                }
            }
        ]
    }

}
