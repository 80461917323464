import React from 'react';
import classes from './HomePage.module.css';
import '../../index.css';
import { textConstants } from '../../constants/TextConstants';
import { pageContentConstants } from '../../constants/PageContentConstants'; 
import {NavLink} from 'react-router-dom';
import Circle from '../../components/Circle/Circle';
import SchedulePickupPage from '../SchedulePickupPage/SchedulePickupPage';
import Stats from '../../components/Stats/Stats';
import { faChevronRightIcon } from '../../shared/fontAwesomeIcons/fontAwesomeIcons';
import ServiceCarousel from '../../components/CustomCarousel/ServiceCarousel';
import ReviewCarousel from '../../components/ReviewCarousel/ReviewCarousel';

const pricing = textConstants.LAUNDRY_PRICING.pricing.options;

const HomePage = (props) => {

    // scroll to top.
    const rootElement = document.documentElement;
    function scrollToTop() {
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return(
        <div className="container overlay">
            {/* Information Section */}
            <div className={classes.homePageInfo}>  
                <div className={classes.info_container}>
                    {/* For desktop screens */}
                    <p className={[classes.homePageInfo__title, "font_xlg", classes.desktopOnly].join(' ')}>
                        FULL SERVICE WASH &amp; FOLD <br/>
                        LAUNDROMAT WITH COMMERCIAL <br/>
                        LAUNDRY &amp; DRY CLEANING <br/>
                        SERVICES
                    </p>
                    {/* For mobile screens */}
                    <p className={[classes.homePageInfo__title, "font_lg", classes.mobileScreensOnly].join(' ')}>
                        FULL SERVICE 
                        WASH &amp; FOLD LAUNDROMAT 
                        WITH COMMERCIAL
                        LAUNDRY &amp; DRY CLEANING
                        SERVICES
                    </p>
                    <p className={`${classes.homePageInfo__description} font_md`}>
                        {pageContentConstants.HOME_PAGE_CONTENTS.DESCRIPTION}
                    </p>
                    {/* <button className={classes.homePageInfo__button}>
                            {textConstants.BUTTON_TEXT.SCHEDULE_PICKUP}
                    </button> */}
                    <div className={classes.homePageInfo__button__container}>
                        <SchedulePickupPage text={textConstants.BUTTON_TEXT.SCHEDULE_PICKUP} variant="button_variant_1" />
                    </div>  
                </div>   
                {/* <div className={classes.image_container}>
                    <img src={aboutUsImage} alt="about-us" />
                </div>  */}
            </div>

            {/* Our Services Section  */}
            <div>
                <ServiceCarousel />
            </div>

            {/* Pricing Section */}
            <div className={classes.homePagePricing}>
               <div className={[classes.homePagePricing__title, "font_lg"].join(' ')}>
                    {pageContentConstants.HOME_PAGE_CONTENTS.PRICING_SECTION_TITLE}
               </div>

               <div className={classes.homePagePricing__container}>
                   {pricing.map((priceDetail, i) => {
                        return (
                            <Circle key={i} content={priceDetail}></Circle>
                        );
                    })}
               </div>

               <div className={classes.pricing_button_container}>
                        <NavLink activeClassName={classes.active} to='/pricing' onClick={scrollToTop}> 
                            <button className={classes.pricing_button}>
                                <span>{textConstants.BUTTON_TEXT.VIEW_ALL}</span>
                                <span className={classes.pricing_button_icon}>{faChevronRightIcon}</span>
                            </button>
                        </NavLink>
               </div>
            </div>

            {/** Stats Section */}
            <div>
                <Stats></Stats>
            </div>

            {/* Testimony Section */}
            <div>
                <ReviewCarousel/>
            </div>
        </div>
    );
}

export default HomePage;