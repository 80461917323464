
import React from 'react';
import { Carousel } from 'primereact/carousel';
import { textConstants } from '../../constants/TextConstants';
import classes from './ReviewCarousel.module.css';
import { pageContentConstants } from '../../constants/PageContentConstants'; 
import googleLogo from '../../assets/images/google-logo-removebg-preview.png'

function ReviewCarousel() {

    const reviewTemplate = (content) => {
        return (
            <div className={classes.reviewContainer}>
                <div className={[classes.carouselContent, "font_md"].join(' ')}>
                    <q><cite>{content.customerReview}</cite></q>
                </div>

                <div className={[classes.carouselSubContent, "font_sm"].join(' ')}>
                        - {content.customerName}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className={[classes.testimonysection__title, 'font_lg'].join(' ')}>
                {pageContentConstants.HOME_PAGE_CONTENTS.TESTIMONY_SECTION_TITLE}
            </div>
            <Carousel autoplayInterval={2000} value={textConstants.CUSTOMER_TESTIMONIALS.values} 
                    numVisible={1} numScroll={1} 
                    orientation="vertical"
                    verticalViewPortHeight="300px"
                    itemTemplate={reviewTemplate} />
            <div className={classes.rating_container}>
                <div className={classes.rating_score}>
                    <img src={googleLogo} alt="Google Logo" className={classes.google_logo} />
                    <span className={classes.rating_score_text}>rating score <strong>4.8</strong> of 5 based on 97 reviews</span>
                </div>
                <a href="https://bit.ly/freshlinen-write-a-review" className={classes.review_button} rel="noreferrer" target="_blank">Leave your review</a>
            </div>
        </div>
    )
}

export default ReviewCarousel;