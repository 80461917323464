import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Footer.module.css';
import '../../index.css';
import { fbIcon, igIcon, twitterIcon } from '../../shared/fontAwesomeIcons/fontAwesomeIcons';
import { textConstants } from '../../constants/TextConstants';
import { pageContentConstants } from '../../constants/PageContentConstants';
import { urlConstants } from '../../constants/UrlConstants';

const navigationOptions = textConstants.FOOTER_OPTIONS_WITH_LINK.options;

const Footer = (props) => {

    const currentYear = new Date().getFullYear();

    return (
        <div className={classes.footerContainer}>
            <div>
                <ul className={classes.footerContainer__list}>
                    { navigationOptions.map((option, i) => {
                            return (<li className={classes.footerContainer__item} key={i}>
                                <NavLink activeClassName={classes.active} to={option.link} onClick={props.clickHandler}> {option.text} </NavLink>
                            </li>);
                        })
                    }
                </ul>
            </div>
            <div>
                <ul className={classes.footerContainer__list}>
                    <li className={classes.footerContainer__item}>
                        <a href={urlConstants.TWITTER_URL} target="_blank" rel="noreferrer nofollow">{twitterIcon}</a>
                    </li>
                    <li className={classes.footerContainer__item}>
                        <a href={urlConstants.FACEBOOK_URL} target="_blank" rel="noreferrer nofollow">{fbIcon}</a>
                    </li>
                    <li className={classes.footerContainer__item}>
                        <a href={urlConstants.INSTAGRAM_URL} target="_blank" rel="noreferrer nofollow">{igIcon}</a>
                    </li>
                </ul>
            </div>
            <div>
                <hr/>
            </div>
            <div className="font-md">
                © {currentYear} {pageContentConstants.HOME_PAGE_CONTENTS.COPYRIGHT_TEXT}
            </div>
        </div>
    );
}

export default Footer;
