import { GoogleSpreadsheet } from "google-spreadsheet";

export const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
export const CONTACT_ME_SHEET_ID = process.env.REACT_APP_CONTACT_ME_SHEET_ID;
export const COMMERCIAL_LAUNDRY_SHEET_ID = process.env.REACT_APP_COMMERCIAL_LAUNDRY_SHEET_ID;
export const SCHEDULE_PICKUP_SHEET_ID = process.env.REACT_APP_SCHEDULE_PICKUP_SHEET_ID;
export const CLIENT_EMAIL = process.env.REACT_APP_CLIENT_EMAIL;
export const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
export const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export function getCurrentDate() {
    const monthNames = [ "January", "February", "March", "April", "May", "June", 
                       "July", "August", "September", "October", "November", "December" ];
    const currentDate = new Date();
    let formattedDate =  monthNames[currentDate.getMonth()] + ' ' + currentDate.getDate() + ' ' +  currentDate.getFullYear();
    return formattedDate;
}

export function getCurrentDay() {
    const weekDay = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentDate = new Date();
    return weekDay[currentDate.getDay()];
}