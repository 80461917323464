import React from 'react';
import { pageContentConstants } from '../../constants/PageContentConstants';
import classes from './AboutUs.module.css';
import aboutUsImage from '../../assets/images/about-us.png';

const aboutUsContent = pageContentConstants.ABOUT_US_PAGE_CONTENT;

const AboutUs = () => {

    return(
        <div className={classes.container}>
            <div className={classes.image_container}>
                <img src={aboutUsImage} alt="about-us" />
            </div>
            <div className={classes.info_container}>
                <div className={[classes.info_container__title, "font_lg"].join(' ')}>
                    {aboutUsContent.TITLE}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {aboutUsContent.DESCRIPTION_1}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {aboutUsContent.DESCRIPTION_2}
                </div>
            </div> 
        </div>
    )
}

export default AboutUs;