import React from 'react';
import classes from './PricingPage.module.css';
import '../../index.css';
import female from '../../assets/images/female-icon.svg';
import male from '../../assets/images/male-icon.svg';
import house from '../../assets/images/house-icon.svg';
import shoe from '../../assets/images/shoe-icon.svg';
import { pricingValues } from '../../constants/Pricing';
import Circle from '../../components/TransparentCircle/Circle';


const dryCleaningPricing = pricingValues.DRY_CLEANING;
const laundryPricing = pricingValues.LAUNDRY;

function getIcon (value) {
    switch(value) {
        case 'men-icon':
            return male;
        case 'female-icon':
            return female;
        case 'house-icon':
            return house;
        case 'shoes-icon':
            return shoe;  
        default:
            return;
    }
}

const PricingPage = (props) => {
    return (
        <div className={classes.container}>
            <div className={[classes.title, "font_lg"].join(' ')}>
                DRY CLEANING
            </div>
            <div className={classes.dry_cleaning_container}>
                {
                    dryCleaningPricing.map((pricing, index) => {
                        return(
                            <div className={classes.dry_cleaning_card} key={index}>
                                <div className={classes.card_icon}>
                                    <img src={getIcon(pricing.PRICEFOR)} alt="price-for-female"></img>
                                </div>
                                <div className={classes.card_content}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th className='font_md'> Items </th>
                                            <th className='font_md'> Price (₹) </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            pricing.DETAILS.map((detail, i) => {
                                                return (<tr key={i}>
                                                    <td className='font_sm'> {detail.item}</td>
                                                    <td className='font_sm'> {detail.price} </td>
                                                </tr>)        
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })
                }

                


            </div>
            <div className={[classes.title, "font_lg"].join(' ')}>
                LAUNDRY
            </div>
            <div className={classes.laundry_container}>
                {
                    laundryPricing.map((pricing, index) => {
                        return (
                            <Circle key={index} content={pricing} ></Circle>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PricingPage;