import washAndFoldImg from '../assets/images/wash-fold-round.png';
import dryCleaningImg from '../assets/images/dry-cleaning-round.png';
import washAndIronImg from '../assets/images/wash-iron-round.png';
import premiumLaundryImg from '../assets/images/premium-laundry-round.png';

export const pricingValues =  {
    DRY_CLEANING : [
        {
            PRICEFOR: 'men-icon',
            DETAILS: [
                {
                    item: 'Shirt/T-shirt/Pant',
                    price: '85'
                },
                {
                    item: 'Denim/Jeans Pant',
                    price: '95'
                },
                {
                    item: 'Kurta/Jubba',
                    price: '95'
                },
                {
                    item: 'Kurta/Jubba - Designer',
                    price: '300'
                },
                {
                    item: 'Normal Dhoti',
                    price: '120'
                },
                {
                    item: 'Silk Dhoti',
                    price: '220'
                },
                {
                    item: 'Blazer/Coat/Safar',
                    price: '300'
                },
                {
                    item: 'Suit 2 Pcs.',
                    price: '330'
                },
                {
                    item: 'Suit 3 Pcs.',
                    price: '430'
                },
                {
                    item: 'Suit 5 Pcs.',
                    price: '500'
                },
                {
                    item: 'Jacket',
                    price: '300+'
                },
                {
                    item: 'Jacket Leather',
                    price: '800'
                },
                {
                    item: 'Sherwani/Bandgala',
                    price: '650'
                },
                {
                    item: 'Tie/Underwear/Vest',
                    price: '40'
                },
                {
                    item: 'Shirt/Towel silk',
                    price: '100'
                },
                {
                    item: 'Cap',
                    price: '90'
                },
                {
                    item: 'Hat',
                    price: '125'
                }
            ]
        },
        {
            PRICEFOR: 'female-icon',
            DETAILS: [
                {
                    item: 'Top/Kurti/Kameez',
                    price: '100'
                },
                {
                    item: 'Tops designer',
                    price: '150+'
                },
                {
                    item: 'Tops designer - Heavy',
                    price: '400+'
                },
                {
                    item: 'Kurti fancy',
                    price: '200'
                },
                {
                    item: 'Salwar/Chudidhar',
                    price: '150'
                },
                {
                    item: 'Dupatta',
                    price: '60+'
                },
                {
                    item: 'Saree Silk/Nrml',
                    price: '260+/150'
                },
                {
                    item: 'Saree Designer',
                    price: '260+'
                },
                {
                    item: 'Blouse Nrml/Designer',
                    price: '75/150'
                },
                {
                    item: 'Lehenga',
                    price: '450+'
                },
                {
                    item: 'Shawl',
                    price: '250+'
                },
                {
                    item: 'Cap',
                    price: '90'
                },
                {
                    item: 'Hat',
                    price: '125'
                },
                {
                    item: 'Underwear/Vest',
                    price: '40'
                }
            ]
        },
        {
            PRICEFOR: 'house-icon',
            DETAILS: [
                {
                    item: 'Curtains - French door',
                    price: '250'
                },
                {
                    item: 'Curtains - Small S/D',
                    price: '110/160'
                },
                {
                    item: 'Curtains - Big S/D',
                    price: '175/235'
                },
                {
                    item: 'Pillow',
                    price: '125'
                },
                {
                    item: 'Pillow Cover-Cotton',
                    price: '25'
                },
                {
                    item: 'Blanket/Quilt/Rug S/D',
                    price: '325/450+'
                },
                {
                    item: 'Bedspread S/D',
                    price: '120/180'
                },
                {
                    item: 'Quilt S/D',
                    price: '270/370'
                },
                {
                    item: 'Carpet',
                    price: '30/sq ft'
                },
                {
                    item: 'Face towel',
                    price: '40+'
                },
                {
                    item: 'Large towel',
                    price: '75'
                },
                {
                    item: 'Bath robe',
                    price: '100'
                },
                {
                    item: 'Socks pair',
                    price: '50'
                }
            ]
        },
        {
            PRICEFOR: 'shoes-icon',
            DETAILS: [
                {
                    item: 'Sports Shoes',
                    price: '300'
                },
                {
                    item: 'Canvas Shoes',
                    price: '300'
                },
                {
                    item: 'Leather Shoes',
                    price: '500'
                },
                {
                    item: 'Suede Leather Shoes',
                    price: '500'
                }
            ]
        }
    ],
    LAUNDRY : [
        {
            serviceName: 'Wash & Fold',
            price: '₹ 100',
            subText: ' (Incl tax)',
            price_unit: '(Per Kg)',
            details: {
                turnAroundTime: '48 hrs turn around',
                packagingType: 'Combined Packaging',
                clothType: 'For Casual & Regular wear'
            },
            tc: 'Minimum 4 Kgs'
        },
        {
            serviceName: 'Wash & Iron',
            price: '₹ 140',
            subText: ' (Incl tax)',
            price_unit: '(Per Kg)',
            details: {
                turnAroundTime: '48 hrs turn around',
                packagingType: 'Combined Packaging',
                clothType: 'For Casual & Regular wear'
            },
            tc: 'Minimum 4 Kgs'
        },
        {
            serviceName: 'Premium Laundry',
            price: '₹ 250',
            subText: ' (Incl tax)',
            price_unit: '(Per Kg)',
            details: {
                turnAroundTime: '72 hrs turn around',
                packagingType: 'Individual Packaging',
                clothType: 'For Formals, Whites & Branded Clothes'
            },
            tc: 'Minimum 4 Kgs'
        },
        {
            serviceName: 'Dry Cleaning',
            price: '₹ 80/piece',
            subText: ' (Incl tax)',
            price_unit: '',
            details: {
                turnAroundTime: '72 hrs turn around',
                packagingType: 'Individual Packaging',
                clothType: 'For Expensive and Fancy Garments'
            },
            tc: ''
        }
    ],
    PRICING_DETAIL: {
        TITLE: 'Service Details',
        SERVICES_OFFERED : [
            {
                serviceName: 'Wash & Fold',
                imageUrl: washAndFoldImg,
                price: '₹ 100',
                price_unit: 'Per Kg',
                details: [
                    '48 hrs Turnaround time',
                    'Machine Wash & Tumble Dry',
                    'Combined Packaging',
                    'Preferred for Casual and Regular Wears',
                    '',
                    '*Minimum 4 Kgs'
                ],
                tc: ''
            },
            {
                serviceName: 'Wash & Iron',
                imageUrl: washAndIronImg,
                price: '₹ 140',
                price_unit: 'Per Kg',
                details: [
                    '48 hrs Turnaround time',
                    'Machine Wash & Tumble Dry',
                    'Combined Packaging',
                    'Preferred for Casual and Regular Wears',
                    'Crisp Ironing through Steam/Hard Press',
                    '*Minimum 4 Kgs'
                ],
                tc: ''
            },
            {
                serviceName: 'Premium Laundry',
                imageUrl: premiumLaundryImg,
                price: '₹ 250',
                price_unit: 'Per Kg',
                details: [
                    '72 hrs Turnaround time',
                    'Machine Wash & Dry',
                    'Individual Packaging with cushioning materials',
                    'Preferred for Formals, Whites, Branded clothes and other Expensive Garments',
                    'Guaranteed Cuff & Collar Cleaning',
                    '*Minimum 4 Kgs'
                ],
                tc: ''
            },
            {
                serviceName: 'Dry Clean',
                imageUrl: dryCleaningImg,
                price: '₹ 80',
                price_unit: 'Per Piece',
                details: [
                    '72 hrs Turnaround time',
                    'Eco-friendly & organic detergents, Spot removal of stains.',
                    'Individual Packaging with cushioning materials',
                    'Preferred for Expensive & Fancy Garments / Heavy Items',
                    'Crisp Ironing through Steam/Hard Press',
                    '*Price may vary as per cloth.'
                ],
                tc: '*Price may vary as per cloth'
            }
        ],
        GENERAL_TC: '*All Prices are inclusive of taxes'
    }
}