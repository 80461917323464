import React from 'react';
import classes from './Circle.module.css';
import '../../index.css';
import { NavLink } from 'react-router-dom';

const Circle = (props) => {

    // scroll to top.
    const rootElement = document.documentElement;
    function scrollToTop() {
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    
    return (
        <div className={classes.circle} style={{backgroundImage: "url('https://4.imimg.com/data4/JF/IQ/GLADMIN-26984716/wp-content-uploads-2016-02-wash-and-fold-500x500.jpg')"}}>
            <div className={classes.normal}>
                <div className={[classes.circle_head, "font_lg"].join(' ')}>
                    {props.content.serviceName}
                </div>
                <div className={[classes.circle_price, "font_lg"].join(' ')}>
                    {props.content.price}
                    <span>{props.content.subText}</span>
                </div>
                <div className={[classes.circle_head, "font_lg"].join(' ')}>
                    {props.content.price_unit}
                </div>
                <div className={[classes.tc, "font_sm"].join(' ')}>
                    {props.content.tc}
                </div>
            </div>

            <div className={classes.overlay}>
                <div className={[classes.circle_text, "font_md"].join(' ')}>
                    {props.content.details.turnAroundTime}
                </div>
                <div className={[classes.circle_text, "font_md"].join(' ')}>
                    {props.content.details.packagingType}
                </div>
                <div className={[classes.circle_text, "font_md"].join(' ')}>
                    {props.content.details.clothType}
                </div>
                <div className={[classes.circle_link, "font_md"].join(' ')}>
                    <NavLink to='/compare-services' onClick={scrollToTop}>Know More...</NavLink>
                </div>
            </div>
            
        </div>
    )
}

export default Circle;