import React from 'react';
import '../../index.css';

const LocateUsPage = (props) => {
    return(
        <div className="container">
            <h1>Locate Us Page...</h1>
        </div>
    );
}

export default LocateUsPage;