import React, { Component } from 'react';
import './App.css';
// import './styles/main.css';
import {Switch, Route, Redirect} from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import ServicesPage from './containers/ServicesPage/ServicesPage';
import LocateUsPage from './containers/LocateUsPage/LocateUsPage';
import HomePage from './containers/HomePage/HomePage';
import WashAndFold from './containers/WashAndFoldPage/WashAndFold';
import DryCleaning from './containers/DryCleaningPage/DryCleaning';
import PickupAndDelivery from './containers/PickupAndDeliveryPage/PickupAndDeliveryPage';
import ContactUsPage from './containers/ContactUsPage/ContactUsPage';
import CommercialLaundryPage from './containers/CommercialLaundryPage/CommercialLaundry';
import PricingPage from './containers/PricingPage/PricingPage';
import SchedulePickupPage from './containers/SchedulePickupPage/SchedulePickupPage';
import AboutUs from './containers/AboutUsPage/AboutUs';
import faqPage from './containers/FaqPage/FaqPage';
import PricingDetail from './containers/PricingDetailPage/PricingDetail';


class App extends Component {
  render() {
    let routes = (
      <Switch>
      <Route path="/home" component={HomePage}></Route>
      <Route path="/" exact component={HomePage}></Route>
      <Route path="/wash-and-fold" component={WashAndFold}></Route>
      <Route path="/dry-cleaning" component={DryCleaning}></Route>
      <Route path="/commercial-laundry" component={CommercialLaundryPage}></Route>
      <Route path="/pickup-and-delivery" component={PickupAndDelivery}></Route>
      <Route path="/services" component={ServicesPage}></Route>
      <Route path="/about-us" component={AboutUs}></Route>
      <Route path="/contact-us" component={ContactUsPage}></Route>
      <Route path="/locateUs" component={LocateUsPage}></Route>
      <Route path="/pricing" component={PricingPage}></Route>
      <Route path="/schedule-pickup" component={SchedulePickupPage}></Route>
      <Route path="/faq" component={faqPage}></Route>
      <Route path="/compare-services" component={PricingDetail}></Route>
      <Redirect to="/"/>
      </Switch>
    );

    return (
      <div className='mt-4'>
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

export default App;
