import React from 'react';
import '../../index.css';
import classes from './ServicesPage.module.css';

const ServicesPage = (props) => {
    return(
        <div className="container">
            <div className={classes.servicesContainer}>
                <p className={classes.title}>
                   <strong>State of the Art Live Dry-Cleaning Lounge</strong>
                </p>
                <p className={classes.subtitle}>
                    <strong>
                    A GLIMPSE INTO FRESH LENIN STORE , NEW COLONY , CHENNAI
                    </strong>
                </p>
                <div className={classes.imageContainer}>
                    <img src="http://uclean.in/assets/front/assets/images/LRM_EXPORT_20180621_154330.jpg" alt="store1"/>
                    <img src="http://uclean.in/assets/front/assets/images/LRM_EXPORT_20180621_170419.jpg" alt="store2"/>
                    <img src="http://uclean.in/assets/front/assets/images/LRM_EXPORT_20180621_170419.jpg" alt="store3"/>
                </div>
                
                <div className={classes.servicesDescriptionContainer}>
                    <p className={classes.servicesDescription}>
                    <strong> FRESH LINEN </strong> 
                    has been on top of the game for revolutionizing the cleaning Industry of India. While the brand has been providing dry-cleaning and premium dry-cleaning services across its stores in India, throughout Noida, Delhi, Gurgaon, Pune, Bangalore, Hyderabad, Kolkata, Chennai etc., we have now introduced Fresh Linen Select, which is a state of the art premium Live dry-cleaning lounge, the first of its kind, right now in Defence Colony, New Delhi. Fresh Linen Select offers an ultimate solution for all garment woes by offering dedicated and customized dry cleaning and fabric care services to enhance your garment’s shelf life. Through our Live Dry-Cleaning store, the first one in Defence Colony, New Delhi, we bring forth transparency and credibility to the best Dry-Cleaning experience, that was unheard of in the past. An extended range of services and a structured process ensure the perfect care, each time, for your worthy possessions. With unparalleled dedication towards getting you the best value for every penny spent, we are constantly striving to achieve Global Standards in dry cleaning. So next time your clothes need to be treated with utmost care and love, remember to look for the best dry-cleaning store near you, only with Fresh LInen
                    </p>
                </div>

            </div>
        </div>
    );
}

export default ServicesPage;