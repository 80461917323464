import React from 'react';
import classes from './DryCleaning.module.css';
import Form from '../../components/Form/GetInTouch/Form';
import { pageContentConstants } from '../../constants/PageContentConstants';
import dryCleaningImage from '../../assets/images/girl-laundry.jpg';
import '../../index.css';
import SchedulePickupPage from '../SchedulePickupPage/SchedulePickupPage';

const DryCleaning = () => {

    return(
        <div className={classes.container}>
            <div className={classes.info_container}>
                <div className={classes.info_container__image}>
                    <img src={dryCleaningImage} alt="wash-and-fold" />
                </div>
                <div className={[classes.info_container__title, "font_lg"].join(' ')}>
                    {pageContentConstants.DRY_CLEANING_PAGE_CONTENT.TITLE}
                </div>
                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.DRY_CLEANING_PAGE_CONTENT.CARD_CONTENT_1}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {pageContentConstants.DRY_CLEANING_PAGE_CONTENT.DESCRIPTION_1}
                </div>
                <div className={[classes.info_container__card, "font_llg"].join(' ')}>
                    {pageContentConstants.DRY_CLEANING_PAGE_CONTENT.CARD_CONTENT_2}
                </div>
                <div className={[classes.info_container__description, "font_md"].join(' ')}>
                    {pageContentConstants.DRY_CLEANING_PAGE_CONTENT.DESCRIPTION_2}
                </div>
                <div className={classes.info_container__action}>
                    {/* <button className="button_variant_2">{pageContentConstants.DRY_CLEANING_PAGE_CONTENT.ACTION_TEXT}</button> */}
                    <SchedulePickupPage text={pageContentConstants.DRY_CLEANING_PAGE_CONTENT.ACTION_TEXT} variant="button_variant_2"></SchedulePickupPage>
                </div>
            </div>
            <div className={classes.form_container}>
                <Form service="Dry Cleaning"/>
            </div>
        </div>
    )
}

export default DryCleaning;